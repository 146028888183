
<!-- RENAMED TO Slider.vue -->
<!-- RENAMED TO Slider.vue -->
<!-- RENAMED TO Slider.vue -->

<script setup>
    import { ref, watch, onMounted } from "vue";

    const props = defineProps({
        filter: { type: Object, required: true },
    });

    const loading = ref(false);

    async function update() {
        loading.value = true;

        const options = {};

        options.viewName = props.filter.dataObject.viewName;
        options.whereClause = props.filter.dataObject.recordSource.combinedWhereClause;
        options.filterString = props.filter.getFilterString(props.filter.field);

        options.maxRecords = 50;
        options.fields = [
            {name: props.filter.field.from, alias: "min1", aggregate: "MIN"},
            {name: props.filter.field.to  , alias: "min2", aggregate: "MIN"},
            {name: props.filter.field.from, alias: "max1", aggregate: "MAX"},
            {name: props.filter.field.to  , alias: "max2", aggregate: "MAX"},
        ];

        console.log("fields", options.fields);

        let rows = await props.filter.dataObject.dataHandler.retrieve(options);

        let min = Math.min(rows[0].min1 ??  Infinity, rows[0].min2 ??  Infinity);
        let max = Math.max(rows[0].max1 ?? -Infinity, rows[0].max2 ?? -Infinity);

        // there's no data to get range from
        if (Math.abs(min) === Infinity || Math.abs(max) === Infinity) {
            min = 0;
            max = 100;
        }

        // if we have a range set
        if (props.filter.field.storage.min && props.filter.field.storage.max) {
            props.filter.field.setTempValue({ min: props.filter.field.storage.min, max: props.filter.field.storage.max });
        }
        // we don't have a range set
        else {
            props.filter.field.setTempValue({ min: min, max: max });
        }

        loading.value = false;
    }

    onMounted(() => {
        update();
    });
</script>

<template>
    <m-container :show-spinner="loading">
        <template v-if="!loading">
            <m-content>
                <div class="p-3 d-flex gap-3">
                    <div class="flex-1">
                        <div>{{ $t("Min") }}</div>
                        <input class="form-control fw-medium" v-model="filter.field.temp.min" />
                    </div>
                    <div class="flex-1">
                        <div>{{ $t("Max") }}</div>
                        <input class="form-control fw-medium" v-model="filter.field.temp.max" />
                    </div>
                </div>
            </m-content>
            <div class="p-3">
                <div class="d-flex justify-content-end">
                    <a class="p-3 d-block fw-medium text-primary" style="text-decoration: none;">
                        {{ $t("Clear") }}
                    </a>
                </div>
                <button class="btn btn-primary w-100">
                    {{ $t("Apply") }}
                </button>
            </div>
        </template>
    </m-container>
</template>
